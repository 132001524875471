.frame {
    width: 100%;
    height: 100%;
    border:0;
    position: absolute;
    top:60px;
    left:0;
    right:0;
    bottom:0;
    background: #ffffff;
}
