.wrapper{
    position:fixed;
    bottom:0;
    left:0;
    right:0
}

.label {
    color: var(--color-gray);
    font-weight: 400;
    font-size:14px;
    display: block;
}
.input {
    display: block;
    color: var(--color-primary);
    border:none;
    border-bottom: 1px solid #cccbcb;
    padding: 4px 6px;
}

.input:focus { outline: 0; box-shadow: none;}

.button {
    background: var(--color-primary);
    border: 0;
    color: white;
    padding: 4px 8px;
    font-size: 1em;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
    border-radius:15px
}

.iconEye{
    position:absolute;
    right:0;
    top:0;
    bottom:0;
    cursor:pointer
}

.logo{
    width:100%;
    padding-left:40px;
    padding-right:40px;
}


@media ( min-width:600px)  { 
    .wrapper{
        left:20%;
        right:20%;
    }
}

@media (orientation:landscape){

}
