.wrapper {
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    flex-basis:calc(50% - 5px);
    background-color: var(--color-primary-high-opacity);
    border-radius:20px;
    flex-grow: 0;
    
}

.icon {
    width: 48px;
    height: 48px;
    margin: 5px auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.label {
    width: 100%;
    text-align: center;
    color: var(--color-primary)
}

.wrapper:hover .label {
    text-decoration: underline;
}

@media ( min-width:600px)  { 
    .wrapper{
        flex:auto;
        max-width:200px;
    }
}