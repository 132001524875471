.backgroundDesktop {
    min-height:250px;
    background:var(--color-primary-soft);
    background: linear-gradient(90deg, var(--color-primary-soft) 0%, var(--color-primary) 90%);
}

.backgroundLogin {
    min-height:100vh;
    background:var(--color-primary-soft);
    background: linear-gradient(90deg, var(--color-primary-soft) 0%, var(--color-primary) 90%);
}