.desktopHeader{
    height:150px;
}

 /* Togliamo l'altezza dei componenti ed eventuali margini*/
.wrapper{
    position: fixed;
    left:0;
    right:0;
    height: 100%;
    background-color:white;
    overflow-y:auto; 
    padding:20px;
    border-top-left-radius:40px;
    border-top-right-radius:40px;
    box-shadow: 0px 0px 6px 0px #0000004d;
}

.iconContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:10px;

}

span.badgeWhite{
    background-color: white!important;
    color:var(--color-primary)!important
}

/* .circleWhite{
    border:2px solid white;
    border-radius:50px;
    width:50px;
    height:50px;
    background-color: var(--color-gray-soft);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    color: var(--color-primary);
} */