.desktopBar{
    height:60px;
    /* position: -webkit-sticky;
    position: sticky; */
    /* position: sticky;
    top: 0; */
    /* overscroll-behavior: none; */
    /* position: fixed; */
    /* -webkit-overflow-scrolling:touch */
}

.bar {
    flex-basis: 20px;
    background: #C0F2FF;
    padding: 0 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.name {

}

.left {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.start {
    height: 0;
    width: 50px;
    border-right: 30px solid;
    border-top: 30px solid;
    box-sizing: content-box;
    background: #C0F2FF url('../../style/img/start.png') no-repeat 18px -27px;
    background-size: auto 24px;
    border-right-color: white;
    border-top-color: transparent;
}
.cut {
    height: 0;
    display: inline-block;
    border-left: 30px solid;
    border-bottom: 30px solid;
    margin-left: -27px;
    width: 0;
    border-left-color: transparent;
    border-bottom-color: #C0F2FF;
}
.separator {
    flex: 1 1 0;
}

.right {
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.pluginName {
    color:white
}
.close {
    width: 30px;
    height: 30px;
    background: url('../../style/img/frame_close_hover.png') no-repeat 50% 50%;
    background-size: auto;
}

.circleWhite{
    border:2px solid white;
    border-radius:50px;
    width:35px;
    height:35px;
    background-color: var(--color-gray-soft);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    color: var(--color-primary);
}

span.badgeWhite{
    background-color: white!important;
    color:var(--color-primary)!important
}