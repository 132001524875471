:root {
  --color-primary: #456DBB;
  --color-primary-opacity: #456cbbf3;
  --color-primary-high-opacity: #F5F6FC;
  --color-primary-soft:#7191CE;
  --color-gray:#747474;
  --color-gray-soft:#e4e4e4;
}

@font-face {
  font-family: 'Myriad';
  src: local('Myriad Pro Regular');
  src: url('./fonts/myriad_pro/MyriadPro-Regular.eot'); /* IE9 Compat Modes */
  src: url('./fonts/myriad_pro/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/myriad_pro/MyriadPro-Regular.woff2') format('woff2'), /* Modern Browsers */
  url('./fonts/myriad_pro/MyriadPro-Regular.woff') format('woff'), /* Modern Browsers */
  url('./fonts/myriad_pro/MyriadPro-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/myriad_pro/MyriadPro-Regular.svg') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

body {
  background: #000000;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-family: 'Myriad', sans-serif !important;
  overflow: hidden;
  overscroll-behavior: none;
}

.container {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
}


.modal {
  position: fixed;
  top: 30svh;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.95);
  padding: 15px;
  border-radius: 5px;
  z-index: 1000;
}

.box-login{
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding:40px 40px;
  box-shadow: 0px 0px 6px 0px #0000004d;
}

.text-gray{
  color:var(--color-gray)
}

.text-primary{
  color:var(--color-primary)!important
}

.bg-blue{
  background-color: var(--color-primary-opacity)!important;
}

.cursor-pointer{
  cursor: pointer;
}

.custom-modal{
  position: fixed;
  width: 90%;
  height: fit-content;
  left: 0;
  top: -60px;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #ffffff;
  border: 1px gray;
  box-shadow: -2px 1px 9px 0 #00000052;
  border-radius: 15px;
  padding:25px;
  z-index:99;
}

.overlay{
  position: fixed;
  background: #22222273;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index:9;
}

@media ( min-width:600px)  { 
  .box-login{
    min-height:55vh
  }
}


@media (orientation : landscape) {
    .box-login{
    
    }
}
