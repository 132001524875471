.menu {
    position: absolute;
    top: 0;
    width: 240px;
    overflow: hidden auto;
    height: 100%;
    box-sizing: border-box;
    z-index: 300;
    left: -250px;
    transition: left 500ms ease-out;
    background: var(--color-primary-opacity);
    box-shadow: 2px 3px 8px 2px #00000026;
}
.menu.show {
    left: 0;
}

.backdrop {
    position: absolute;
    z-index: 290;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.group {
    border-radius: 5px;
    margin-top:10px;
}
.option {
    padding: 8px 14px;
    cursor:  pointer;
    color: #ffffff;
}
.label {

}
.icon {

}
.separator {

}

.header{
    padding:8px 14px;
}

.headerIcon{
    width:50px
}